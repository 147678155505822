/**
 * This vuex store is used for manipulation with data about issues of the user.
 */

import api from '../classes/api';
import router from '../../router';

const state = {
  issues: [],
  files: [],
  topIssues: [],
  tags: [],
  issue: {},
  invoice: {},
  issue_id: null
};

const getters = {
  allIssues: state => state.issues,
  allTopIssues: state => state.topIssues,
  allTags: state => state.tags,
  currentIssue: state => state.issue,
  currentIssueId: state => state.issue_id,
  currentInvoice: state => state.invoice,
  currentIssueFiles: state => state.files,
};

const actions = {

  async fetchIssues({ commit }) {
    const res = await api.get('issues');

    if (res[0]) {
      commit('setIssues', res);
      return true;
    }

  },
    async fetchIssueNumber()
    {
        return await api.get('issues/next_issue');
    },
    async fetchInvoiceNumber()
    {
        return await api.get('issues/next_invoice');
    },
  async fetchTopIssues({ commit }) {
    const res = await api.get('issues/top');

    if (res[0]) {
      commit('setTopIssues', res);
      return true;
    }

  },

  async fetchTags({ commit }) {
    const res = await api.get('issues/tags');
    commit('setTags', res.tag);
    return true;
  },

  async fetchIssue({ commit }, id) {
    const res = await api.get(`issues/${id}`);
    commit('setIssue', res);
  },

  makeCurrentIssue({ commit }, id) {
    router.push({name: 'IssueDetail'});
    commit('setCurrentIssueId', id)
  },

  async addIssue({ commit }, data) {
    const res = await api.post('issues', data);

    if(res.msg === "Success.")
    commit('newIssue', data);
    return res;
  },

  async addOperation({ commit }, data) {
    data.issueid = state.issue_id;

    const res = await api.post(`issues/${state.issue_id}/ops`, data);

    if (res.op !== undefined) {
      commit('newOperation', res.op);
    }
    else
      return res;
  },
  async createInvoice({ commit }, data) {
    const res = await api.post(`invoices`, data);

    return res;
  },
  async fetchInvoice({ commit }, id) {
    const res = await api.get(`invoices/${id}`);
    commit('setInvoice', res);
  },
  async updateIssue({ commit }, data) {
    const res = await api.put(`issues/${data.issueid}`, data);
    if (res.status == 200) {
      commit('updateIssue', data);
    }else{
      throw new Error(`Status: ${res.status}\n ${res.data.msg}`);
    }

    return res
  },

  async updateOperation({ commit }, data) {
    const res = await api.put(`ops/${data.opid}`, data);
    if (res.status == 200) {
      commit('updateOperation', data);
    } else {
      throw new Error(`Status: ${res.status}\n ${res.data}`);
    }
  },

  async deleteIssue({ commit }, id) {
    const res = await api.delete(`issues/${id}`);

    if(res.msg == "Success.")
      commit('removeIssue', id);

  },

  async deleteInvoice({ commit }, id) {
    const res = await api.delete(`invoices/${id}`);

    if(res.msg == "Success.")
      commit('removeInvoice', id);
  },

  async deleteIssueOperation({ commit }, id) {
    const res = await api.delete(`ops/${id}`);
    commit('removeOperation', id);
    return res;
  },

  async fetchIssueFiles({ commit }, id) {
    const res = await api.get(`issues/${id}/files`);
    commit('setIssueFiles', res);
  },

  async uploadIssueFile({commit}, data) {
    const res = await api.postFormData(`cars/upload/${data.id}`, data.formData);

    if(res[0])
      commit('newIssueFile', res);

    return res;
  },

  async deleteIssueFile({ commit }, id) {
    const res = await api.delete(`cars/file/${id}`);

    if(res.msg == "Success.")
      commit('removeIssueFile', id);
  },
};

const mutations = {
    setInvoice: (state, invoice) => (state.invoice = invoice),
  setIssues: (state, issues) => (state.issues = issues),
  setTopIssues: (state, topIssues) => (state.topIssues = topIssues),
  setTags: (state, tags) => (state.tags = tags),

  setIssue: (state, issue) => (state.issue = issue),

  setCurrentIssueId: (state, issue_id) => (state.issue_id = issue_id),

  newIssue: (state, issue) => state.issues.push(issue),

  newOperation: (state, op) => state.issue.ops.push(op),


  updateOperation: (state, data) => state.issue.ops.forEach((op, index) => {
    if (op.opid == data.opid) state.issue.ops[index] = data
  }),

  removeIssue: (state, id) =>
    (state.issues = state.issues.filter(issue => issue.issueid !== id)),

  removeInvoice: (state, id) => {
    state.issue.invoice_id = 0;
  },

  removeOperation: (state, id) => {
    state.issue.ops.forEach((op, i) => {
      if (op.opid == id) state.issue.ops.splice(i, 1);
    });
  },

  updateIssue: (state, updIssue) => {
    state.issues.findIndex(issue => issue.issueid === updIssue.issueid);
    // if (index !== -1) {
    //   state.issues.splice(index, 1, updIssue);
    // }
  },

  removeIssueFile: (state, id) =>
    (state.files = state.files.filter(file => file.fileid !== id)),

  newIssueFile: (state, files) => {
    if(state.files == '') {
      state.files = files;
    } else {
      files.forEach(file => {
        state.files.push(file)
      });
    }
  },

  setIssueFiles: (state, files) => (state.files = files),
};

export default {
  state,
  getters,
  actions,
  mutations
};
